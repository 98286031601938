import React, {FormEvent, useEffect, useRef, useState} from 'react';
import NoSsr from '@mui/material/NoSsr';
import { createTheme } from '@mui/material/styles';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';

// const ReCAPTCHA = React.lazy(() => import('react-google-recaptcha'));
import CalendarArrival from './partials/CalendarArrival';
import CalendarDeparture from './partials/CalendarDeparture';
import {useDispatch, useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../../helpers/MethodHelper";
import {getSearchDefaultOptionsAction, loadSearchOptionsAction, sendContactForm} from "../../api/client";
import {Alert} from "@mui/lab";
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons/faSpinner';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReCAPTCHA from "react-google-recaptcha"
import SearchIcon from "../header/partials/SearchIcon";
import AsyncCreatableSelect from "react-select/async-creatable";
import Checkers from "../../helpers/class/Checkers";
import {useRouter} from "next/router";
import { setIsSuccess } from '../../redux/actions';

interface ContactFormProps {
  showDestination?: boolean
  showDates?: boolean
  searchbarRadius?: number
  captchaSiteKey?: string
  options?: any
  containerClass?: string
  experienceId?: number
  experienceTitle?: string
  insideSingleProperty: boolean
}

export default function ContactForm ( props : ContactFormProps ) {

  const router = useRouter()

  const dispatch = useDispatch()

  const captchaSiteKeySelector = useSelector(state => state['appSettings']['captchaSiteKey'])

  const captchaSiteKey = props.captchaSiteKey != undefined ? props.captchaSiteKey : captchaSiteKeySelector

  const contactAskName = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'contactAskName')
  );

  const contactAskEmail = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'contactAskEmail')
  );

  const contactAskPhone = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'contactAskPhone')
  );

  const contactAskDatesSelector = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'contactAskDates')
  );

  const contactAskDates = props.showDates != undefined ? props.showDates : contactAskDatesSelector

  const contactAskDestinationSelector = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'contactAskDestination')
  );

  const contactAskDestination = props.showDestination != undefined ? props.showDestination : contactAskDestinationSelector

  // useEffect( () => {
  //   console.log("forDebug", contactAskName, contactAskEmail, contactAskPhone, contactAskDates, contactAskDestination)
  // })

  //----------------CALENDAR INIT STYLES------------------
  const calendarMaterialTheme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          root: {
            position: 'absolute !important',
            marginLeft: '-14px !important',
            marginTop: '-16px !important',
            fontSize: '14px !important',
            opacity: '1 !important',
            color: '#999!important',
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          shrink: {
            display: 'none!important'
          }
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            width: '-webkit-fill-available'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            padding: '1px 0',
            fontSize: '14px',
            color: '#000'
          },
          notchedOutline: {
            display: 'none!important'
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            margin: '15px 0 35px 0',
            borderRadius: props.searchbarRadius != null ? props.searchbarRadius + 'px' : '10px'
          }
        }
      }
    }
  });
  const [value, setValue] = React.useState<DateRange<Date>>([null, null]);
  const setOpen = useState(false);

  const [arrival, setArrival] = React.useState(null);
  const [departure, setDeparture] = React.useState(null);
  const calendar = {
    value: value,
    setValue: setValue,
    arrival: arrival,
    setArrival: setArrival,
    departure: departure,
    setDeparture: setDeparture,
    customTheme: calendarMaterialTheme,
  };

  const pageId = useSelector(state => state['propertiesParams']['page_id']);

  const [showResponseMessage, setShowResponseMessage] = useState(false)

  const [formSuccess, setFormSuccess] = useState(null)

  const [formSubmitting, setFormSubmitting] = useState(false)

  const recaptchaRef = React.createRef();

  const [reCaptchaValiddation, setReCaptchaValiddation] = useState(0);



  const servicesGoogleTagManagerId = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'servicesGoogleTagManagerId')
  );

  const datalayer = window.dataLayer

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setFormSubmitting(true)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const recaptchaValue = recaptchaRef.current.getValue();

    if (recaptchaValue.length === 0) {
      setReCaptchaValiddation(2)
      setFormSubmitting(false)

      return;
    } else {
      setReCaptchaValiddation(1)
    }

    let data = new FormData(event.currentTarget)

    let formObject = Object.fromEntries(data.entries());
    formObject.arrival = arrival
    formObject.departure = departure

    if ( Checkers.isValidNumber(props.experienceId) && Checkers.isValidString(props.experienceTitle) ) {
      formObject.experienceId = (props.experienceId).toString()
      formObject.experienceTitle = props.experienceTitle
    }

    // console.log("event", formObject, formObject)

    let response = await sendContactForm({'page_id': pageId}, formObject)

    setShowResponseMessage(true)

    if (response.success == 1) {
      setFormSuccess(true)
      if (Checkers.isValidString(servicesGoogleTagManagerId)) {
        datalayer.push({'event': 'contact_form_submission', 'pagePath': router.asPath});
      }
      !props.insideSingleProperty ? router.push( "/contact/success" ) : dispatch(setIsSuccess(true));
    } else {
      setFormSuccess(false)
    }
    setFormSubmitting(false)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    event.target.reset()
  }

  const customSearch = useSelector( state => state['propertiesParams']['search']);

  const [ defaultSearchOptions, setDefaultSearchOptions ] = useState( null );
  
  useEffect( () => {
    getSearchDefaultOptionsAction( pageId, window.location.pathname ).then( response => {
      setDefaultSearchOptions( response.options );
      if ( response.defaultLocationValue != undefined ) {
        setSelectedSearchLocation( response.defaultLocationValue )
      } else {
        let customOption = {
          value: customSearch,
          label: customSearch
        }
        if ( customOption.value!=null ) {
          setSelectedSearchLocation(customOption)
        }
      }
    });
  }, [pageId]);

  const loadSearchOptions = ( inputValue, callback ) => {
    loadSearchOptionsAction( pageId, inputValue, callback )
  }

  const [ selectedSearchLocation, setSelectedSearchLocation ]= useState( null );

  const handleInputChange = ( input ) => {
    setSelectedSearchLocation( input );

    let location_val = input.value;
    let location_data = location_val.split( "_" );
    let location_type = location_data[0];
    let location_id = location_data[1];
    // switch ( location_type ) {
    //   case 'property':
    //     location.href = window.location.origin + "/property/" + location_id;
    //     break;
    // }
  };

  const colourStyles = {
    control: styles => ({
      ...styles, backgroundColor: 'transparent', border: "none !important", boxShadow: "none !important"
    })
  };

  const getContainerClass = () => {
    if ( props.containerClass!=undefined && Checkers.isValidString(props.containerClass) ) {
      return props.containerClass
    }

    return "col-12 col-sm-12 col-md-6 ps-md-5"
  }

  return (

      <div className={getContainerClass()}>
        <p className="fw-normal text-master">Fill up this form to contact us if you have any further questions</p>
        {
          showResponseMessage ?
              <>
                {
                  formSuccess ?
                      <Alert severity="success" sx={{mb: "20px"}}>Contact form was submitted successfully</Alert>
                      :
                      formSuccess != null ?
                          <Alert severity="error" sx={{mb: "20px"}}>There was a problem while submitting contact
                            form</Alert>
                          :
                          <></>
                }
              </>
              :
              <>
              </>
        }
        {
          reCaptchaValiddation == 2 ?
              <Alert severity="warning" sx={{mb: "20px"}}>You must click the reCaptcha button!</Alert>
              :
              <></>
        }
        <form onSubmit={handleSubmit}>
          {
            contactAskName ?
                <div className="row">
                  <div className="col-6 col-md-6">
                    <div className="form-group form-group-default bg-white b-transparent-master required">
                      <label htmlFor="first_name" className="bold text-uppercase letter-sp-normal fs-12">First
                        name</label>
                      <input id="first_name" name="first_name" type="text" className="form-control" required/>
                    </div>
                  </div>
                  <div className="col-6 col-md-6">
                    <div className="form-group form-group-default bg-white b-transparent-master required">
                      <label htmlFor="last_name" className="bold text-uppercase letter-sp-normal fs-12">Last
                        name</label>
                      <input id="last_name" name="last_name" type="text" className="form-control"/>
                    </div>
                  </div>
                </div>
                : <></>
          }

          <div className="row">
            {
              contactAskEmail ?
                  <div className={contactAskPhone ? 'col-6 col-md-6' : 'col-12'}>
                    <div className="form-group form-group-default bg-white b-transparent-master required">
                      <label htmlFor="email" className="bold text-uppercase letter-sp-normal fs-12">Email</label>
                      <input id="email" name="email" type="email" className="form-control"
                             placeholder="You will get your reply to this email" required/>
                    </div>
                  </div>
                  : <></>
            }
            {
              contactAskPhone ?
                  <div className={contactAskEmail ? 'col-6 col-md-6' : 'col-12'}>
                    <div className="form-group form-group-default bg-white b-transparent-master required">
                      <label htmlFor="phone" className="bold text-uppercase letter-sp-normal fs-12">Phone</label>
                      <input id="phone" name="phone" type="tel" className="form-control"
                          // pattern="[0-12]{3}"
                             placeholder="Phone with Country Code" required/>
                    </div>
                  </div>
                  : <></>
            }
          </div>
          {
            contactAskDestination && !props.insideSingleProperty ?
                <div className="row">
                  <div className="col-12">
                    <div className={`form-group form-group-default bg-white b-transparent-master ${props.insideSingleProperty ? '' : 'required'} `} style={{overflow: 'visible', zIndex: 99}}>
                      <label htmlFor="destination"
                             className="bold text-uppercase letter-sp-normal fs-12">Destination/Property</label>
                      {/*<input id="destination" name="destination" type="text" className="form-control"*/}
                      {/*       placeholder="Type your destination or property here" required/>*/}


                      <AsyncCreatableSelect
                          noOptionsMessage={() => 'nothing found'}
                          loadingMessage={() => 'searching...'}
                          cacheOptions={false}
                          defaultOptions={defaultSearchOptions}
                          loadOptions={loadSearchOptions}
                          onChange={handleInputChange}
                          // defaultValue={defaultLocationValue}
                          value={selectedSearchLocation}
                          className="SearchBar-Form__form-group-select"
                          id="location_property"
                          name="location_property"
                          inputId="searchright"
                          formatCreateLabel={ (inputValue: string) => {
                            return ('Search for ' + inputValue)
                          }}
                          instanceId="location_property"
                          isSearchable
                          options={props.options}
                          // styles={props.customStyles}
                          styles={colourStyles}
                          placeholder={'Where are you going'}
                          blurInputOnSelect
                          menuPlacement={'bottom'}
                          components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                      />

                    </div>
                  </div>
                </div>
                : <></>
          }
          {
            contactAskDates ?
                <div className="row">
                  <div className="col-6 col-md-6">
                    <div className="form-group form-group-default bg-white b-transparent-master required">
                      <label htmlFor="email" className="bold text-uppercase letter-sp-normal fs-12">Arrival</label>
                      <NoSsr>
                        <CalendarArrival
                            id={'checkin'}
                            name={'checkin'}
                            calendar={calendar}
                        />
                      </NoSsr>
                    </div>
                  </div>
                  <div className="col-6 col-md-6">
                    <div className="form-group form-group-default bg-white b-transparent-master required">
                      <label htmlFor="phone" className="bold text-uppercase letter-sp-normal fs-12">Departure</label>
                      <NoSsr>
                        <CalendarDeparture
                            id={'checkout'}
                            name={'checkout'}
                            calendar={calendar}
                        />
                      </NoSsr>
                    </div>
                  </div>
                </div>
                : <></>
          }
          <div className="row">
            <div className="col-12">
              <div className=" form-group form-group-default bg-white b-transparent-master required">
                <label htmlFor="message" className="bold text-uppercase letter-sp-normal fs-12">Message</label>
                <textarea className="form-control" placeholder="Type the message you wish to send" id="message"
                          name="message" style={{minHeight: '100px'}}/>
              </div>
            </div>
          </div>
          {
            captchaSiteKey != undefined && captchaSiteKey != null  && Checkers.isValidString(captchaSiteKey)?
                  <>
                    <div className="row justify-content-between w-100 m-40">
                      <div className="col-12 d-flex justify-content-left">
                        <ReCAPTCHA ref={recaptchaRef} sitekey={captchaSiteKey}/>
                      </div>
                    </div>
                  </>
                  :
                  <>
                  </>
          }
          <div className="row justify-content-between w-100 m-0">
            <p className="font-arial hint-text small pull-left letter-sp-normal fs-12 w-auto m-t-5 px-0">I certify that
              the above information is accurate and true.</p>
            <button id="contact_submit"
                    className="btn btn-design-color w-auto text-uppercase letter-sp-normal fw-normal"
                    disabled={formSubmitting}
                    style={{padding: '6px 17px'}}>
              {
                formSubmitting ?
                    <FontAwesomeIcon icon={faSpinner} spin style={{marginRight: "10px"}}/>
                    :
                    <></>
              }
              <span>Submit</span>
            </button>
          </div>
        </form>
      </div>
  )
}